<!--修改用户信息-->
<template>
    <div class="user-info-edit">
        <div class="title">
            <h5>修改个人信息</h5>
            <span v-if="!fristEdit" class="iconfont close-edit" @click="handleClose">&#xe65f;</span>
        </div>
        <div class="card">
            <span class="iconfont">&#xe696;</span>身份证号：{{ formData.Certificate_Number }}
        </div>
        <el-form ref="formData" :model="formData" :rules="rules">
            <div class="item-input">
                <div class="item">
                    <label><i class="iconfont iconyonghu"></i>用户名:</label>
                    <el-input v-model="formData.User_Name"></el-input>
                </div>
                <div class="item">
                    <label><i class="iconfont iconyouxiang1"></i>邮箱:</label>
                    <el-input v-model="formData.Email"></el-input>
                </div>
                <div class="item">
                    <label><i class="iconfont icondenglu1"></i>登录名:</label>
                    <el-form-item prop="Login_Name">
                        <el-input v-model="formData.Login_Name"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <label><i class="iconfont icondianhua2"></i>手机号:</label>
                    <el-form-item prop="LoginName">
                        <el-input disabled v-model="formData.LoginName"></el-input>
                    </el-form-item>
                    <!-- <p class="tips LoginNameTips">
                        <i class="iconfont icontishi"></i>修改手机号即修改登录名
                    </p> -->
                </div>
                <div class="item">
                    <label><i class="iconfont iconNO" style="font-size: 0.12rem;"></i>编号:</label>
                    <el-input v-model="formData.User_Code"></el-input>
                </div>
                <div class="item">
                    <label><i class="iconfont iconnianling1"></i>年龄:</label>
                    <el-input v-model="formData.User_Age"></el-input>
                </div>
                <div class="item">
                    <label><i class="iconfont iconxingbie"></i>性别:</label>
                    <el-select v-model="formData.User_Gender" placeholder="请选择">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="item">
                    <label><i class="iconfont iconzhiwei"></i>职务/岗位/工种:</label>
                    <el-input v-model="formData.User_Post"></el-input>
                </div>
            </div>
            <div class="line"></div>
            <div class="switch-setpwd" v-show="!fristEdit">
                <span>是否修改密码 </span>
                <el-switch
                    v-model="isModifiedPwd"
                    active-color="#1577d2"
                >
                </el-switch>
            </div>
            <div class="reset-pwd-warning" v-show="fristEdit">
                为了您的账户安全，首次登录需要重新设置密码
            </div>
            <div class="item-input setpwd" v-show="isModifiedPwd">
                <div class="item">
                    <label class="required"><i class="iconfont iconmima1"></i>新密码:</label>
                    <el-form-item prop="NewPassword" :rules="isModifiedPwd?rules.NewPassword:[]">
                        <el-input placeholder="请输入密码" v-model="formData.NewPassword" show-password></el-input>
                    </el-form-item>
                    <p class="tips" ref="newPasswordTips">
                        <i class="iconfont icontishi"></i>8-30位，可以使用 数字、字母、特殊字符，至少包含两种
                    </p>
                </div>
                <div class="item">
                    <label class="required"><i class="iconfont iconmima1"></i>确认密码:</label>
                    <el-form-item prop="ConfirmPassword" :rules="isModifiedPwd?rules.ConfirmPassword:[]">
                        <el-input placeholder="请再次输入密码" v-model="formData.ConfirmPassword" show-password></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="valid-pwd">
                <label class="required"><i class="iconfont iconmima1"></i>密码:</label>
                <el-form-item prop="Password">
                    <el-input placeholder="请输入当前密码" v-model="formData.Password" show-password></el-input>
                </el-form-item>
                <a class="sure-btn" @click="submitData">确认</a>
            </div>
        </el-form>
    </div>
</template>

<script>
import md5 from 'js-md5';
import currencyFun from '@/assets/js/currencyFun';

export default {
    name: 'user-info-edit',
    data() {
        const checkPwd = (rule, value, callback) => {
            const pattern = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,30}$/;
            if (value !== '') {
                if (!pattern.test(value)) {
                    this.$refs.newPasswordTips.style.color = '#FF4E50';
                    callback(new Error(' '));
                } else {
                    this.$refs.newPasswordTips.style.color = '#979EA7';
                    callback();
                }
            } else {
                callback(new Error(' '));
            }
        };
        const checkTwoPwd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入新密码'));
            } else if (value !== this.formData.NewPassword) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        };
        return {
            formData: {},
            options: [{
                value: 1,
                label: '男',
            }, {
                value: 0,
                label: '女',
            }],
            fristEdit: false, // 首次设置需要更改密码
            rules: {
                Login_Name: [{
                    required: true,
                    message: '请输入登录名',
                    trigger: 'blur',
                }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '格式错误, 请输入手机号',
                    trigger: 'blur',
                }],
                LoginName: [{
                    required: true,
                    trigger: 'blur',
                    validator: this.checkPhone,
                }],
                NewPassword: [
                    { required: true, validator: checkPwd, trigger: 'blur' },
                ],
                ConfirmPassword: [ { required: true, validator: checkTwoPwd, trigger: 'blur' }],
                Password: [ { required: true, message: '请输入当前密码', trigger: 'blur' }],
            },
            tenant_id: '',

            isModifiedPwd: true,
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.initPage();
    },
    methods: {
        initPage() {
            this.tenant_id = this.$takeTokenParameters('TenantId');
            const IsResetPwd = this.$takeTokenParameters('IsResetPwd');
            if (IsResetPwd === '0') {
                this.isModifiedPwd = true;
            } else {
                this.isModifiedPwd = false;
            }
            // 首次编辑（改密码）
            if (this.extr && this.extr.fristEdit) {
                this.fristEdit = this.extr.fristEdit;
            }
            this.formData.UserId = this.$takeTokenParameters('Uid');
            this.getUserInfo();
        },
        checkPhone(rule, value, callback) {
            if (value) {
                const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/; // 手机号正则表达式
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            } else {
                callback();
            }
        },
        // 获取用户信息
        getUserInfo() {
            this.$axios
                .get(`/interfaceApi/basicplatform/users/${this.formData.UserId}`)
                .then(data => {
                    data.IsModifiedPwd = false;
                    data.Password = '';
                    data.NewPassword = '';
                    data.LoginName = data.Login_Name;
                    this.formData = data;
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 提交数据
        submitData() {
            this.$refs.formData.validate(async valid => {
                if (valid) {
                    const checkResult = await this.checkPassWord();
                    if (checkResult) {
                        this.commitUsersInfo();
                    } else {
                        this.$message.error('当前密码错误');
                    }
                }
            });
        },
        // 校验密码
        async checkPassWord() {
            let data = null;
            try {
                data = await this.$axios
                    .get(`/interfaceApi/platform/user_manage/checkpassword/${this.md5(this.formData.Password)}`);
            } catch (error) {
                this.$message.error(error);
            }
            if (data) {
                return true;
            }
            return false;

        },
        // 用户信息提交
        commitUsersInfo() {
            const data = {
                Id: this.formData.User_Id,
                RealName: this.formData.User_Name,
                LoginName: this.formData.Login_Name,
                Mobile: this.formData.Mobile,
                Email: this.formData.Email || '',
                State: 1,
                UserType: 1,
                CertificateType: '1',
                CertificateNumber: this.formData.Certificate_Number || '',
                UserCode: this.formData.User_Code || '',
                UserGender: this.formData.User_Gender,
                UserAge: this.formData.User_Age || 0,
                UserPost: this.formData.User_Post || '',
                OrgId: this.formData.Org_ID || '',
                OrgName: this.formData.Org_Name || '',
                RoleInfos: {},
                TenantId: this.tenant_id,
                LogonRights: this.formData.LogonRights,
                IsModifiedPwd: this.isModifiedPwd,
                Password: '',
                NewPassword: '',
                soure_type: 3,
            };
            data.Password = this.md5(this.formData.Password);
            data.NewPassword = this.md5(this.formData.NewPassword);
            this.$axios
                .put('/interfaceApi/platform/user_manage/modify_and_sync', data)
                .then(async () => {
                    this.$parent.hide();
                    if (data.IsModifiedPwd) {
                        this.editPwdSucc();
                    }

                    // 更新用户信息
                    await currencyFun.getUserInfo();

                    this.$message.success('修改成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 改密成功
        editPwdSucc() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '7rem',
                height: '5rem',
                t_url: 'userinfo/editPwdSucc',
                extr: {},
            });
        },
        md5(val) {
            return md5(val + '');
        },
        // 关闭
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.user-info-edit
    height 100%;
    position relative
    z-index 99;
    .title
        height: 0.5rem;
        background: #4F67AD;
        display flex
        justify-content center
        align-items center;
        font-size 0.2rem;
        color #fff;
        position relative;
        .close-edit
            position absolute
            right 0.2rem;
            top 50%
            transform rotate(45deg) translateY(-50%)
            font-weight 700
            cursor:pointer
    .card
        height: 0.5rem;
        background: #FAFAFA
        display flex
        align-items center;
        font-size 0.2rem;
        color #5588F1;
        padding-left 0.2rem;
        span
            margin-right 10px;
    .item-input
        margin-top 0.15rem;
        display flex
        flex-wrap wrap;
        align-items center;
        justify-content space-between
        padding 0 0.3rem;
        >>> .el-form-item__content
                height 0.4rem
                line-height 0.4rem !important
        .item
            margin 0.15rem 0;
            display flex;
            align-items center;
            /*width 48%;*/
            position relative
            &:nth-child(2n) label
                width 1.7rem;
            label
                display flex;
                align-items center
                width 1.1rem;
                font-size 0.16rem;
                color #666666;
                i
                    margin-right 0.03rem;
            .el-input,/deep/ .el-input
                width: 2.4rem;
                height: 0.4rem;
                background: #FFFFFF;
                border-radius: 2px;
            .tips
                width: 2.4rem;
                position: absolute;
                right: -0.05rem;
                top: 0.42rem;
                font-size: 0.12rem;
                color: #979ea7;
                .iconfont
                    font-size 0.12rem;
                    margin-right 0.03rem;
                &.LoginNameTips
                    right auto
                    left 0.05rem
    .valid-pwd
        margin-top 0.3rem;
        display flex
        align-items center;
        height: 1.3rem;
        background: #FAFAFA;
        border: 1px solid #F0F0F0;
        padding 0 0.2rem;
        font-size 0.16rem;
        color #5588F1;
        label
            margin-right 0.15rem;
        .iconfont
            margin-right 0.03rem;
        .el-input,/deep/ .el-input
            flex 1;
            height 0.4rem;
            input
                vertical-align: top
                height 0.4rem;
                border-radius: 0;
                border: 1px solid #5588F1;
        .sure-btn
            display flex;
            justify-content center;
            align-items center;
            width: 0.8rem;
            height: 0.4rem;
            background: #1577D2;
            border-radius: 0rem 0.02rem 0.02rem 0rem;
            font-size 0.16rem;
            color #fff;
            cursor: pointer;
        .el-form-item,/deep/ .el-form-item
            flex 1;
            .el-form-item__content
                height 0.4rem
                .el-input__suffix
                    line-height 0.4rem
    .line
        height: 1px;
        background: #D7D7D7;
        margin 0.2rem 0;
    .switch-setpwd
        padding-left 0.3rem;
        display flex
        align-items center;
        span
            margin-right 0.1rem;
    .reset-pwd-warning
        font-size 0.16rem;
        color #FA8014;
        padding-left 0.3rem;
    .required i:after
        content "*"
        color #E02020
    .el-form-item,/deep/ .el-form-item
        margin-bottom 0;
</style>
